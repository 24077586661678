<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="사용자"
            name="userId"
            v-model="searchParam.userId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="푸시받은 일시"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="로그인로그 이력"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'push-message',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'title',
            field: 'title',
            label: '푸시제목',
            align: 'center',
            sortable: true,
          },
          {
            name: 'content',
            field: 'content',
            label: '푸시내용',
            align: 'center',
            sortable: true,
          },
          {
            name: 'pushUserName',
            field: 'pushUserName',
            label: '푸시받은 사람',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regDt',
            field: 'regDt',
            label: '푸시받은 일시',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        startYmd: '',
        endYmd: '',
        userId: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      consultUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.push.history.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
